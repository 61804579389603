import { writable } from 'svelte/store';

export type ToastType = 'error' | 'warning' | 'success' | 'info';
interface Toast {
	id: number;
	type: ToastType;
	dismissible: boolean;
	timeout: number;
	message: string;
}

export const toasts = writable([] as Toast[]);

export const dismissToast = (id: number) => {
	toasts.update((all) => all.filter((t) => t.id !== id));
};

export const addToast = (toast: {
	type?: ToastType;
	dismissible?: boolean;
	timeout?: number;
	message: string;
}) => {
	const id = Math.floor(Math.random() * 100000);
	const defaults: Toast = {
		id,
		type: 'error',
		dismissible: true,
		timeout: 3000,
		message: ''
	};
	const t = { ...defaults, ...toast };
	toasts.update((all) => [t, ...all]);
	if (t.timeout) setTimeout(() => dismissToast(id), t.timeout);
};
